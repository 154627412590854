<template>
    <div class="add_teacher">
        <el-card class="box-card">
            <el-form ref="addFromRef" :rules="rules" :model="addFrom" label-width="110px">
                <el-form-item label="标题:">
                    秒杀标题设置
                </el-form-item>
                <el-form-item label="" prop="title">
                    <!-- 单选 -->
                    <el-input v-model="addFrom.title" placeholder="请输入秒杀标题" @blur="validateRatio"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="success" @click="submitForm">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <div class="tables">
            <el-table ref="singleTable" height='250' :data="tableData" highlight-current-row
                @current-change="handleCurrentChange" style="width: 100%">
                <el-table-column type="index" width="50">
                </el-table-column>
                <el-table-column property="masterName" label="操作人">
                </el-table-column>
                <el-table-column property="cur" label="修改前内容">
                </el-table-column>
                <el-table-column property="end" label="修改后内容">
                </el-table-column>
                <el-table-column property="addTime" label="操作时间">
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination @size-change="handleSizeChange" @current-change="Changes" :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="totalItems">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { baseSetForBack, getBaseSet } from "../../api/administrator.js";
import { modifySeckillTitle } from "../../api/basicMarketing.js";
export default {
    data() {
        return {
            addFrom: {
                title: '',
            },
            tableData: [],
            currentPage: 1,
            pageSize: 10,
            totalItems: null,
            currentRow: '',
            rules: {
                title: [
                    { required: true, message: '请输入秒杀标题', trigger: 'blur' },
                    { validator: this.validateTitle, trigger: ['blur', 'change'] } // 自定义校验，触发时机包括 blur 和 change
                ]
            }
        };
    },
    created() {
        this.initDate()
        this.getBase()
    },
    methods: {
        // 自定义校验函数
        validateTitle(rule, value, callback) {
            if (!value) {
                return callback(new Error('请输入秒杀标题'));
            }
            if (value.length > 10) {
                return callback(new Error('标题不能超过10个字'));
            }
            callback(); // 校验通过
        },
        getBase() {
            getBaseSet({ id: 8 }).then((res) => {
                if (res.data.code == 0) {
                    this.addFrom.title = res.data.data.ratio;
                    this.this.initDate()
                }
            });
        },
        submitForm() {
            this.$confirm('确定要提交吗？', '确认', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 用户点击了“确定”按钮
                modifySeckillTitle({
                    title: this.addFrom.title
                }).then((res) => {
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        this.initDate()
                    }
                    console.log(res);
                    // 可以在这里添加更多的处理逻辑
                }).catch((error) => {
                    console.error('提交失败:', error);
                });
            }).catch(() => {
                // 用户点击了“取消”按钮
                console.log('用户取消了评分提交');
            });
        },
        initDate() {
            baseSetForBack({ baseId: 8, pageSize: this.pageSize, currentPage: this.currentPage }).then((res) => {
                this.tableData = res.data.list
                this.totalItems = res.data.pagination.total
                console.log(99, this.totalItems);
            })
        },
        handleSizeChange(val) {
            this.pageSize = val // 每次修改 pageSize 时，重置到第一页
            this.initDate(); // 重新加载数据
            console.log(`每页 ${val} 条`);
        },
        Changes(val) {
            this.currentPage = val;
            this.initDate()
            console.log(`当前页: ${val}`);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
            console.log(val, 777);
        },
    },
};
</script>
  
<style scoped>
.avatar-uploader-icon {
    border: 1px dashed #eee;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 150px;
    height: 150px;
}

.box-card {
    padding-bottom: 50px;
}

.tables {
    width: 100%;
    height: 100%;
    position: relative;
}

.block {
    position: absolute;
    right: 20px;
    bottom: -50px;
}

.el-input {
    width: 400px;
}
</style>
  