import request from '../utils/request'
import baseUrl from './baseUrl'

export const modifySeckillTitle = (data) => request({
  url: baseUrl + '/baseSet/modifySeckillTitle',
  method: 'POST',
  data
})
export const modifyPresale = (data) => request({
  url: baseUrl + '/baseSet/modifyPresale',
  method: 'POST',
  data
})